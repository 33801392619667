<script setup lang="ts">
import { useStore } from 'vuex'
import { AppDropdown } from '#components'

withDefaults(defineProps<{
  orientation?: 'left' | 'right'
}>(), {
  orientation: 'left',
})

const config = useRuntimeConfig()
const colorMode = useColorMode()
const store = useStore()

const accountDropdown = ref<InstanceType<typeof AppDropdown> | undefined>(undefined)
defineExpose({ accountDropdown })

const mobile = computed(() => !!store.getters.uix?.mobile)

function closeAccountActionsDropdown() {
  accountDropdown.value?.close()
}
function closeMenuMobile() {
  // Only close menu on mobile.
  if (mobile.value) {
    setTimeout(() => {
      store.dispatch('updateUI', { sideMenuOpen: false })
    }, 100) // Original: 150ms
  }
}
function accountActionClick() {
  closeAccountActionsDropdown()
  closeMenuMobile()
}
async function refreshApp() {
  // if (navigator && ('serviceWorker' in navigator)) {
  //   const reg = await navigator.serviceWorker.ready
  //   await reg.update()
  // }
  console.log('Reload...')
  reloadNuxtApp()
}
function refreshAllData() {
  // NOTE: it looks like this is not working properly, the data is
  // being fetched from workbox cache instead of the API.
  store.dispatch('updateUIx', { syncing: true })
  store.dispatch('refreshData')
    .finally(() => {
      store.dispatch('updateUIx', { syncing: false })
    })
}
function requestHelp() {
  try {
    window.location.href = `mailto:${config.public.helpEmail}?subject=Help`
  }
  catch (e) {
    alert(`
      Unfortunately it seems like we can not open your e-mail client automatically.
      You can contact us at ${config.public.helpEmail}.
    `)
  }
}
function openDocumentation() {
  window.open(config.public.docsUrl, '_blank')
}
function openShortcutsInfoDialog() {
  store.dispatch('updateUIx', { shortcutsInfoDialogOpen: true })
}
function toggleDarkMode() {
  // store.dispatch('toggleDarkMode')
  switch (colorMode.preference) {
    case 'light':
      colorMode.preference = 'dark'
      break
    case 'dark':
      colorMode.preference = 'system'
      break
    default:
      colorMode.preference = 'light'
      break
  }
}
function logout() {
  navigateTo('/logout')
}
</script>

<template>
  <AppDropdown
    ref="accountDropdown"
    :orientation="orientation"
    icon="fa6-solid:user"
    title="Account menu"
    class="action account-dropdown"
  >
    <AppDropdownItem
      to="/account"
      label="Account"
      icon="fa6-regular:address-card"
      @click="accountActionClick();"
    />
    <AppDropdownItem
      to="/settings"
      label="Settings"
      icon="fa6-solid:gears"
      @click="accountActionClick();"
    />
    <AppDropdownItem
      :label="themeLabels[colorMode.preference as keyof typeof themeLabels]"
      :icon="themeIcons[colorMode.preference as keyof typeof themeIcons]"
      @click="toggleDarkMode(); useTrackEvent('click: Change theme (dropdown)', { props: { path: $route.fullPath } })"
    />
    <AppDropdownItem
      v-if="!mobile"
      label="Shortcuts"
      icon="fa6-solid:keyboard"
      @click="openShortcutsInfoDialog(); accountActionClick(); useTrackEvent('click: Shortcuts (dropdown)', { props: { path: $route.fullPath } })"
    />
    <div class="dropdown-divider" />
    <AppDropdownItem
      label="Documentation"
      icon="fa6-solid:book"
      @click="openDocumentation(); closeAccountActionsDropdown();"
    />
    <AppDropdownItem
      label="Help"
      icon="fa6-solid:handshake-angle"
      @click="requestHelp(); closeAccountActionsDropdown(); useTrackEvent('click: Help (dropdown)', { props: { path: $route.fullPath } })"
    />
    <AppDropdownItem
      to="/updates"
      label="Updates"
      icon="fa6-solid:code"
      @click="accountActionClick()"
    />
    <div class="dropdown-divider" />
    <AppDropdownItem
      v-if="mobile"
      label="Refresh app"
      icon="mobile-device"
      @click="refreshApp(); closeAccountActionsDropdown(); useTrackEvent('click: Refresh app (dropdown)', { props: { path: $route.fullPath } })"
    />
    <AppDropdownItem
      label="Refresh data"
      icon="syncing"
      @click="refreshAllData(); closeAccountActionsDropdown(); useTrackEvent('click: Refresh data (dropdown)', { props: { path: $route.fullPath } })"
    />
    <AppDropdownItem
      v-if="store.getters.profile?.developer"
      to="/developer"
      label="Developer"
      icon="fa6-solid:terminal"
      @click="accountActionClick()"
    />
    <div class="dropdown-divider" />
    <AppDropdownItem
      label="Logout"
      icon="fa6-solid:right-from-bracket"
      @click="logout(); accountActionClick(); useTrackEvent('click: Logout', { props: { path: $route.fullPath } })"
    />
  </AppDropdown>
</template>
